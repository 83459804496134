import { useEffect, useState } from 'react'

function usePrevious<T>(value: T) {
  const [previous, setPrevious] = useState<T | null>(null)
  useEffect(() => {
    return () => {
      setPrevious(value)
    }
  }, [value])
  return previous
}

export default usePrevious
