'use client'
import MessagesContext from './MessagesContext'
import { Message, MessagesContextType, MessagesProviderProps } from './types'
import { useCallback, useState } from 'react'
function MessagesProvider({ locale, children, initialMessages }: MessagesProviderProps) {
  const [messages, setMessages] = useState<Array<Message>>(initialMessages ?? [])

  const addMessage: MessagesContextType['addMessage'] = useCallback(
    (message) => {
      const id = message.id ?? `${message.type}-${message.message?.toLocaleString()}`
      setMessages((prevMessages) => {
        const nextMessages = prevMessages.filter((msg) => msg.id !== id)
        nextMessages.push({ ...message, id, locale })
        return nextMessages
      })
      return id
    },
    [locale]
  )
  const removeMessage: MessagesContextType['removeMessage'] = useCallback((messageId) => {
    setMessages((prevMessages) => {
      if (!prevMessages.length) return prevMessages
      const nextMessages = prevMessages.filter((message) => message.id !== messageId)
      if (nextMessages.length === prevMessages.length) return prevMessages
      return nextMessages
    })
  }, [])

  const reset: MessagesContextType['reset'] = useCallback(() => {
    setMessages([])
  }, [])

  return (
    <MessagesContext.Provider
      value={{
        messages,
        addMessage,
        removeMessage,
        reset,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export default MessagesProvider
